import styles from "./styles.module.css";
import React from "react";
import Img from "../../assets/images/cov.jpg";
import "../../global.css";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Divider, Spin } from "antd";
import EventCard from "../../components/EventCard";
import {
  Carousel as Antc,
  Button,
  Card,
  Skeleton,
  notification,
  Empty,
  Image,
} from "antd";
import {
  faMusic,
  faLaugh,
  faDance,
  faFutbol,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CategoryCard from "../../components/categoryCard";
import ConcertImg from "../../assets/images/concerts.jpeg";
import Comedy from "../../assets/images/comedy.jpg";
import Food from "../../assets/images/food.jpg";
import fest from "../../assets/images/fest.jpg";
import Sport from "../../assets/images/sport.jpg";
import Theatre from "../../assets/images/theater.jpg";
import Mount from "../../assets/images/mount.jpeg";
import Dance from "../../assets/images/dance.jpg";
import Partner from "../../assets/images/partner.png";
import { Carousel as Tail, IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import basic from "../../assets/images/allpic.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  tickets,
  filterTickets,
  googleLogin,
  getcategory,
  getcities,
  recenteventsData,
} from "../../requests";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../slice";
import { useDispatch } from "react-redux";
import Loader from "../../components/loadercustom";
import pt1 from "../../assets/images/pt1.jpeg";
import pt2 from "../../assets/images/pt2.jpeg";
import pt3 from "../../assets/images/pt3.jpeg";
import pt4 from "../../assets/images/pt4.jpeg";
import pt5 from "../../assets/images/pt5.jpeg";
import pt6 from "../../assets/images/pt6.jpeg";
import pt7 from "../../assets/images/pt7.jpeg";
import pt8 from "../../assets/images/pt8.jpeg";
import pt9 from "../../assets/images/pt-9.jpg";
import ev from "../../assets/images/WhatsApp Image 2023-11-13 at 14.02.58_618a1eff.jpg";
import ev2 from "../../assets/images/IMG_8517.jpg";
import ev3 from "../../assets/images/IMG_8518.jpg";
import ev4 from "../../assets/images/Linked.jpg";
import { setCities, setCategories } from "../../sideData";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setrefresh] = useState(false);
  const [AllTickets, setTickets] = useState([]);
  const [FilteredTickets, setfilteredtickets] = useState([]);
  const city = useSelector((state) => state?.userData?.city);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  // const [cities, setcities] = useState([]);
  // const [warningVisible, setWarningVisible] = useState(false);
  // const [caterories, setcategories] = useState([]);
  const cities = useSelector((state) => state?.sideData?.cities);
  const caterories = useSelector((state) => state?.sideData?.categories);
  const [recentEvents, setrecentevents] = useState([]);

  // console.log(cities);

  // console.log(recentEvents)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const displayCity = city ? city.toUpperCase() : "DELHI";
  const images = [ev, ev2, ev3, ev4];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const partners = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const partnersImages = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const resphead = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      // partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      // partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 20,
    },
  };

  const logincheck = async () => {
    try {
      const resp = await googleLogin();
      // console.log(resp.data.user, "check--------------");

      dispatch(setUser(resp.data.user));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    logincheck();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleSearch = () => {
    if (selectedCategory && selectedLocation) {
      navigate(
        `/search?category=${selectedCategory}&location=${selectedLocation}`
      );
    } else {
      notification.error({
        message: "Search Error",
        description: "Both category and location are required for search.",
      });
    }
  };

  const CustomArrow = ({ onClick, icon }) => (
    <div className={styles.customArrow} onClick={onClick}>
      {icon}
    </div>
  );

  const CustomArrowleft = ({ onClick, icon }) => (
    <div className={styles.customArrowleft} onClick={onClick}>
      {icon}
    </div>
  );

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const sliderRef = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
  };

  const RecentEvents = async () => {
    try {
      const resp = await recenteventsData();
      // console.log(resp.data.data.ticketInfo);
      setrecentevents(resp.data.data.ticketInfo);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    RecentEvents();
  }, []);

  const memoizedTickets = useMemo(async () => {
    try {
      const resp = await tickets();
      return resp.data.data.tickets;
    } catch (err) {
      console.log(err);
      return [];
    }
  }, []);

  const memoizedFiltered = useMemo(
    () => async () => {
      try {
        setrefresh(true);
        const resp = await filterTickets({ city: displayCity });
        const filteredTickets = resp.data.filteredTickets.filter(
          (ticket) => ticket.status === "publish"
        );
        setfilteredtickets(filteredTickets);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
        setrefresh(false);
      }
    },
    [displayCity]
  );

  useEffect(() => {
    memoizedFiltered();
  }, [displayCity, memoizedFiltered]);

  useEffect(() => {
    memoizedTickets.then((tickets) => {
      // Filter the tickets based on the 'status' key
      const publishedTickets = tickets.filter(
        (ticket) => ticket.status === "publish"
      );
      setTickets(publishedTickets);
    });
  }, [memoizedTickets]);

  const getcategoryall = async () => {
    try {
      const resp = await getcategory();
      // setcategories(resp.data);
      dispatch(setCategories(resp.data));
      console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getcategoryall();
  }, []);

  const citiesGet = async () => {
    try {
      const resp = await getcities();
      // setcities(resp.data);
      dispatch(setCities(resp.data));
      console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    citiesGet();
  }, []);

  const skeletonCard = (
    // <div className={styles.crousgap}>
    <div style={{ padding: "10px" }}>
      <Skeleton active />
    </div>
  );
  const formatDate = (dateString) => {
    const eventDateObject = new Date(dateString);
    return eventDateObject.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleViewMoreClick = () => {
    // Assuming genre is a parameter in your route
    // navigate(`/events/category/${genre.toLowerCase()}`);

    // const encodedGenre = encodeURIComponent(value.toLowerCase());

    navigate(`/events`);
  };

  const handlegalley = () => {
    navigate(`/gallery`);
  };

  return (
    <>
      {loading ? (
        // Loading state
        <Loader loading={loading} />
      ) : (
        <div>
          {FilteredTickets.length === 0 ? (
            <Empty
              description="No events for the selected city. Please select a different city."
              style={{ marginTop: "100px" }}
            />
          ) : (
            <Slider {...settings} ref={sliderRef}>
              {FilteredTickets.map((item, index) => (
                <div key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${item.eventPhoto})`,
                      height: "630px",
                      backgroundSize: "cover",
                      backgroundColor: "rgba(58, 58, 58, 0.54)",
                    }}
                  >
                    <div
                      className={styles.box}
                      style={{ position: "relative" }}
                    >
                      <CustomArrowleft
                        icon={<FaChevronLeft />}
                        onClick={prevSlide}
                      />
                      <div className={styles.headcrous}>
                        <div
                          className={styles.TwoParts}
                          
                        >
                          <div className={styles.detail}>
                            <p className={styles.small}>
                              {item.eventCategory.charAt(0).toUpperCase() +
                                item.eventCategory.slice(1)}
                            </p>

                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                gap: "7px",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <p className={styles.med}>{item.name}</p>
                              <p className={styles.large}>{item.artistName}</p>
                              <p
                                className={styles.small}
                                style={{ fontWeight: "400" }}
                              >
                                {item.venue},{item.city}
                              </p>
                              <p
                                className={styles.small}
                                style={{ fontWeight: "400" }}
                              >
                                <span style={{ marginRight: "10px" }}>
                                  <FontAwesomeIcon icon="fa-calendar-days" />
                                </span>
                                {formatDate(item.eventDate)} | {item.time}
                              </p>
                            </div>
                            <div style={{ gap: "18px", display: "flex" }}>
                              <Link to={`/details/${item._id}`}>
                                <button
                                  className={`rounded   font-bold ${styles.buynowHead}`}
                                  style={{ backgroundColor: "#FF275E" }}
                                >
                                  BOOK NOW
                                </button>
                              </Link>
                              <Link to={`/details/${item._id}`}>
                                <button
                                  className={`rounded bg-white  font-bold border-red-500 ${styles.moreHead}`}
                                  style={{
                                    color: "#FF275E",
                                    border: "#FF275E 2px solid",
                                  }}
                                >
                                  MORE INFO
                                </button>
                              </Link>
                            </div>
                          </div>
                          <div className={styles.ticketimg}>
                            <div className={styles.dashedBorder}></div>
                            <img
                              className={styles.ticketImage}
                              alt="img"
                              src={item.eventPhoto}
                            />
                          </div>
                        </div>
                      </div>
                      <CustomArrow
                        icon={<FaChevronRight />}
                        onClick={nextSlide}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}

          <div className={styles.search}>
            <div className="container">
              <div
                className="p-6 rounded-lg shadow-md flex flex-col md:flex-row md:items-center md:justify-between mx-auto w-full md:w-1/2"
                style={{ background: "white", border: "solid 1px #eee" }}
              >
                <div className=" md:w-3/4 md:mr-4">
                  {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}
                  <div className="flex gap-5 flex-col md:flex-row">
                    <div className="relative">
                      <label
                        htmlFor="category"
                        className="block text-sm font-medium text-gray-700"
                      >
                        <span style={{ marginLeft: "11px" }}>Category</span>
                      </label>
                      <select
                        style={{ width: "153px" }}
                        id="category"
                        name="category"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        className="focus:outline-none block w-full mt-1 p-2 border-0 rounded-none focus:ring-indigo-500 focus:border-indigo-500 bg-transparent"
                      >
                        <option value="">Select Category</option>
                        {caterories?.map((category) => (
                          <option key={category.name} value={category.name}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Divider type="vertical" className={styles.divider} />
                    <div className="relative">
                      <label
                        htmlFor="eventType"
                        className="block text-sm font-medium text-gray-700"
                      >
                        <span style={{ marginLeft: "11px" }}>Location</span>
                      </label>
                      <select
                        style={{ width: "153px" }}
                        id="eventType"
                        name="eventType"
                        value={selectedLocation}
                        onChange={handleLocationChange}
                        className="focus:outline-none block w-full mt-1 p-2 border-0 rounded-none focus:ring-indigo-500 focus:border-indigo-500 bg-transparent"
                      >
                        <option value="">Select Location</option>
                        {cities?.map((city) => (
                          <option key={city.name} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <button
                  className="text-white font-medium p-3 rounded-md hover:bg-red-600 px-5"
                  style={{ backgroundColor: "#FF275E" }}
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <div
              className="trendingHeading"
            >
              <p className="trendingText">
                Trending in{" "}
                <span className="text-[#FF275E]">{`"${city}"`}</span>
              </p>
              <Button
                type="link"
                className="viewmore"
                onClick={() => handleViewMoreClick()}
              >
                View More
              </Button>
            </div>
            {!loading &&  FilteredTickets.length===0 && <Empty description={`No Events Found in ${city}`} />}
            {!loading &&  FilteredTickets.length>0 &&
            <Carousel responsive={responsive}>
              {FilteredTickets.length > 0
                ? FilteredTickets.map((event) => (
                    <div className={styles.crousgap} key={event.id}>
                      <EventCard event={event} />
                    </div>
                  ))
                : Array.from({ length: 5 }).map((_, index) => (
                    <React.Fragment key={index}>{skeletonCard}</React.Fragment>
                  ))}
            </Carousel>
}
          </div>
          <br></br>
          <br></br>

          <div className="container">
            <div
              
              className="trendingHeading"
            >
              <p className="trendingText">
                Trending in <span className="text-[#FF275E]">"India"</span>
              </p>
              <Button
                type="link"
                className="viewmore"
                onClick={() => handleViewMoreClick()}
              >
                View More
              </Button>
            </div>
            <Carousel responsive={responsive}>
              {AllTickets.length > 0
                ? AllTickets.map((event) => (
                    <div className={styles.crousgap} key={event.id}>
                      <EventCard event={event} />
                    </div>
                  ))
                : Array.from({ length: 3 }).map((_, index) => (
                    <React.Fragment key={index}>{skeletonCard}</React.Fragment>
                  ))}
            </Carousel>
          </div>

          <br></br>
          <br></br>

          <div className="container">
            <p className="trendingText" style={{marginBottom:'10px'}}>Search by Category</p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 justify-items-center">
              <CategoryCard
                image={ConcertImg}
                category="CONCERT"
                icon="fa-music"
              />
              <CategoryCard
                image={Comedy}
                category="COMEDY"
                icon="fa-masks-theater"
              />
              <CategoryCard
                image={Dance}
                category="DANCE"
                icon="fa-people-robbery"
              />
              <CategoryCard
                image={Sport}
                category="SPORTS"
                icon="fa-basketball"
              />
              <CategoryCard
                image={Theatre}
                category="THEATRE & ART"
                icon="fa-film"
              />
              <CategoryCard
                image={fest}
                category="FESTIVITIES"
                icon="fa-masks-theater"
              />
              <CategoryCard
                image={Mount}
                category="ADVENTURE"
                icon="fa-person-hiking"
              />
              <CategoryCard image={Food} category="FOOD" icon="fa-burger" />
            </div>
          </div>
          <br></br>

          <div className="container">
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "30px",
                }}
              >
                <p className="trendingText">Our Recent Events</p>
                {/* <Button
                  type="link"
                  style={{
                    color: "#ff275e",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                  onClick={() => handlegalley()}
                >
                  View More
                </Button> */}
              </div>

              <div className="pt-8">
                <Image.PreviewGroup>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                    {recentEvents?.map((image, index) => (
                      <div key={index} className="w-full h-auto">
                        <Image
                          src={image.thumbnail}
                          alt={`Event ${index + 1}`}
                          // className="w-full h-full object-cover"
                         className={styles.recentEventsImages}
                        />
                        <div className="text-center font-bold text-lg">
                          <p>{image.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Image.PreviewGroup>
              </div>
            </div>
          </div>
          <br></br>

          <div
            style={{
              background: "black",
              marginTop: "70px",
              marginBottom: "70px",
              height: "350px",
              display: "flex",
              alignItems: "center",
            }}
            // className={styles.PartnersDiv}
          >
            <div className="container">
              <p className="text-white font-bold text-3xl text-center">
                OUR PARTNERS
              </p>
              {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 pt-20 justify-items-center"> */}
              <div className="pt-10 ">
                <Carousel
                  responsive={partners}
                  centerMode
                  centerSlidePercentage={80}
                >
                  <img src={pt9} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt3} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt8} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt7} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt1} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt2} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt5} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt4} alt="Partner 1" className="w-40 h-auto p-4" />
                  <img src={pt6} alt="Partner 1" className="w-40 h-auto p-4" />
                </Carousel>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
      <Loader loading={refresh} />
    </>
  );
};

export default Home;
